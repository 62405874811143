import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import ProductList from './components/ProductList';
import HeroSection from './components/HeroSection';
import Login from './components/Login';
import Signup from './components/Signup';
import Cart from './components/Cart';
import ShopByCategory from './components/ShopByCategory'; // Import the new component
import Footer from './components/Footer.js';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <HeroSection />
       
        <Routes>
          <Route path="/" element={<><ShopByCategory /><ProductList /></>} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/cart" element={<Cart />} />
        </Routes>
      </div>
      <Footer />
    </Router>
    
  );
}

export default App;

import React from 'react';
import './ProductList.css'; // Import CSS file for styling

const products = [
  { id: 1, name: "Olive hair oil", price: "₹299", image: "https://www.zzzone.co.uk/wp-content/uploads/2021/05/Creative-Product-Photography-2.jpg", category: "Electronics" },
  { id: 2, name: "Nike shoe", price: "₹199", image: "https://images.unsplash.com/photo-1542291026-7eec264c27ff?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80", category: "Clothing" },
  { id: 3, name: "Tshirt", price: "₹99", image: "https://www.gapfactory.com/webcontent/0052/921/902/cn52921902.jpg", category: "Home" },
  { id: 4, name: "Product 4", price: "₹399", image: "https://www.gapfactory.com/webcontent/0029/496/639/cn29496639.jpg", category: "Books" },
  { id: 5, name: "Product 5", price: "₹89", image: "https://images.unsplash.com/photo-1615397349754-cfa2066a298e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8cHJvZHVjdHxlbnwwfHwwfHx8MA%3D%3D", category: "Beauty" },
  { id: 6, name: "Product 6", price: "₹149", image: "https://images.unsplash.com/photo-1523275335684-37898b6baf30?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvZHVjdHxlbnwwfHwwfHx8MA%3D%3D", category: "Sports" },
  { id: 7, name: "Product 7", price: "₹229", image: "https://plus.unsplash.com/premium_photo-1707932495000-5748b915e4f2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8ZmFzaGlvbnxlbnwwfHwwfHx8MA%3D%3D", category: "Toys" },
  { id: 8, name: "Product 8", price: "₹349", image: "https://images.unsplash.com/photo-1548874468-025d0edfdf8b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fGdhZGdldHN8ZW58MHx8MHx8fDA%3D", category: "Furniture" }
];

const getProducts = (section) => {
  // Here, you can replace this with actual data fetching or conditional logic based on the section
  return products.slice(0, 6);
};

const ProductList = () => {
  return (
    <div className="product-list">
      <h1>Featured Products</h1>
      <div className="product-grid">
        {products.slice(0, 6).map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
      <div className="offer-section">
        <h2>Special Offers</h2>
        <div className="offer-grid">
          <div className="offer-card">50% Off on Electronics</div>
          <div className="offer-card">Buy 1 Get 1 Free on Clothing</div>
          <div className="offer-card">20% Off on Home Appliances</div>
        </div>
      </div>
      <div className="additional-sections">
        <Section title="New Arrivals" />
        <Section title="Collections" />
        <Section title="Bestsellers on Fashion" />
        <Section title="More Products" />
      </div>
    </div>
  );
};

const ProductCard = ({ product }) => (
  <div className="product-card">
    <div className="product-image">
      <img src={product.image} alt={product.name} />
      <button className="product-add-to-cart" aria-label="Add to Cart">
        <i className="fa fa-shopping-cart"></i>
      </button>
    </div>
    <div className="product-info">
      <h2 className="product-title">{product.name}</h2>
      <ul className="rating">
        <li className="fas fa-star"></li>
        <li className="fas fa-star"></li>
        <li className="fas fa-star"></li>
        <li className="fas fa-star"></li>
        <li className="fas fa-star disable"></li>
      </ul>
      <p className="product-price">{product.price}</p>
    </div>
  </div>
);

const Section = ({ title }) => {
  const products = getProducts(title);

  return (
    <div className="section">
      <h2>{title}</h2>
      <div className="product-grid">
        {products.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
        <button className="view-all-products">View All Products</button>
      </div>
    </div>
  );
};

export default ProductList;

import React from 'react';
import './Footer.css'; // Import the CSS file for footer styling

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-section">
                    <h3 className="footer-heading">About Us</h3>
                    <p className="footer-text">
                        We are a leading e-commerce platform offering a wide range of products with top-notch quality and service. 
                        Our mission is to provide an exceptional shopping experience for our customers.
                    </p>
                </div>
                <div className="footer-section">
                    <h3 className="footer-heading">Quick Links</h3>
                    <ul className="footer-links">
                        <li><a href="#home">Home</a></li>
                        <li><a href="#products">Products</a></li>
                        <li><a href="#offers">Special Offers</a></li>
                        <li><a href="#contact">Contact Us</a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h3 className="footer-heading">Contact Us</h3>
                    <p className="footer-text">
                        Email: support@example.com<br />
                        Phone: +123 456 7890<br />
                        Address: 123 Main Street, City, Country
                    </p>
                </div>
                <div className="footer-section">
                    <h3 className="footer-heading">Follow Us</h3>
                    <div className="footer-socials">
                        <a href="https://facebook.com" className="social-icon"><i className="fab fa-facebook-f"></i></a>
                        <a href="https://twitter.com" className="social-icon"><i className="fab fa-twitter"></i></a>
                        <a href="https://instagram.com" className="social-icon"><i className="fab fa-instagram"></i></a>
                        <a href="https://linkedin.com" className="social-icon"><i className="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 Your Company Name. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;

import React from 'react';

const Cart = () => {
  return (
    <div>
      <h1>Cart Page</h1>
      {/* Add cart details here */}
    </div>
  );
};

export default Cart;

import React from 'react';

const Login = () => {
  return (
    <div>
      <h1>Login Page</h1>
      {/* Add login form here */}
    </div>
  );
};

export default Login;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; // Import CSS file for styling
import { FaSearch, FaUser, FaShoppingCart, FaBars, FaTimes } from 'react-icons/fa'; // Icons for search, user, and cart

const categories = [
  "Electronics",
  "Clothing",
  "Home",
  "Books",
  "Beauty",
  "Sports",
  "Toys",
  "Furniture"
];

const Navbar = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/">Logo</Link>
      </div>
      <div className="navbar-search">
        <input type="text" placeholder="Search for products, brands, and more" />
        <button type="button">
          <FaSearch />
        </button>
      </div>
      {/* Toggle Button for Mobile */}
      <button
        className="navbar-toggle-button"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        {isMenuOpen ? <FaTimes /> : <FaBars />}
      </button>
      <div className={`navbar-menu ${isMenuOpen ? 'open' : ''}`}>
        <Link to="/">Home</Link>
        <div 
          className="navbar-categories" 
          onMouseEnter={() => setShowDropdown(true)}
          onMouseLeave={() => setShowDropdown(false)}
        >
          <span>Categories</span>
          {showDropdown && (
            <div className="dropdown-menu">
              {categories.map((category, index) => (
                <div className="dropdown-item" key={index}>
                  <h4>{category}</h4>
                  <ul>
                    {[...Array(2)].map((_, i) => (
                      <li key={i}>
                        <Link to={`/${category.toLowerCase()}`}>{category}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          )}
        </div>
        <Link to="/offers">Offers</Link>
        <Link to="/become-seller">Become a Seller</Link>
      </div>
      <div className={`navbar-actions ${isMenuOpen ? 'open' : ''}`}>
        <Link to="/login">
          <FaUser />
          <span>Login</span>
        </Link>
        <Link to="/signup">
          <span>Sign Up</span>
        </Link>
        <Link to="/cart">
          <FaShoppingCart />
          <span className="cart-badge">3</span> {/* Example cart item count */}
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;

import React from 'react';

const Signup = () => {
  return (
    <div>
      <h1>Sign Up Page</h1>
      {/* Add sign-up form here */}
    </div>
  );
};

export default Signup;

// src/components/HeroSection.js

import React, { useEffect, useRef } from 'react';
import './HeroSection.css'; // Import CSS file for styling

const HeroSection = () => {
  const sliderRef = useRef(null);
  const slideInterval = 3000; // Change slide every 3 seconds

  useEffect(() => {
    const slider = sliderRef.current;
    const slideWidth = slider.offsetWidth / slider.children.length;
    let interval;

    const startAutoScroll = () => {
      interval = setInterval(() => {
        if (slider.scrollLeft + slider.clientWidth >= slider.scrollWidth) {
          slider.scrollTo({ left: 0, behavior: 'smooth' });
        } else {
          slider.scrollBy({ left: slideWidth, behavior: 'smooth' });
        }
      }, slideInterval);
    };

    startAutoScroll();

    // Cleanup on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="hero-section">
      <div className="hero-content">
        <h1>Welcome to Our Store</h1>
        <p>Find the best products at unbeatable prices!</p>
        <button>Shop Now</button>
      </div>
      <div className="hero-background">
        <iframe
          src="https://my.spline.design/bganimation-a71fbc4f65ab1fa8158a19635c76925b/"
          frameBorder="0"
          width="100%"
          height="100%"
          title="Background Animation"
        ></iframe>
      </div>
      <div className="hero-banner">
        <button className="scroll-arrow left" onClick={() => sliderRef.current.scrollBy({ left: -sliderRef.current.offsetWidth / sliderRef.current.children.length, behavior: 'smooth' })}>
          <i className="fa fa-chevron-left"></i>
        </button>
        <div className="banner-slider" ref={sliderRef}>
          <div className="banner-slide">
            <img src="https://img.lazcdn.com/us/domino/20d27af0-5890-43a1-b91f-fdbb82359585_NP-1976-688.jpg_2200x2200q80.jpg_.webp" alt="Banner 1" />
          </div>
          <div className="banner-slide">
            <img src="https://img.lazcdn.com/us/domino/6b199741-660e-4486-a8c2-b2a154d3ed51_NP-1976-688.jpg_2200x2200q80.jpg_.webp" alt="Banner 2" />
          </div>
          <div className="banner-slide">
            <img src="https://img.lazcdn.com/us/domino/e15504ff-1c92-4034-b93c-816daee4490c_NP-1976-688.jpg_2200x2200q80.jpg_.webp" alt="Banner 3" />
          </div>
        </div>
        <button className="scroll-arrow right" onClick={() => sliderRef.current.scrollBy({ left: sliderRef.current.offsetWidth / sliderRef.current.children.length, behavior: 'smooth' })}>
          <i className="fa fa-chevron-right"></i>
        </button>
      </div>
    </div>
  );
};

export default HeroSection;



import React from 'react';
import './ShopByCategory.css'; // Import the component-specific CSS

const categories = [
  { name: 'Electronics', image: 'https://rukminim2.flixcart.com/fk-p-flap/80/80/image/4da1d0d19350cc84.jpg?q=100' },
  { name: 'Clothing', image: 'https://images.hindustantimes.com/img/2022/08/25/1600x900/pexels-antoni-shkraba-7081113_1661414759537_1661414794423_1661414794423.jpg' },
  { name: 'Home', image: 'https://rukminim2.flixcart.com/fk-p-flap/80/80/image/5b813b64a3179898.jpg?q=100' },
  { name: 'Books', image: 'https://tse1.mm.bing.net/th?id=OIP.n3sAXxDyR0gM6xT1ZwmXdQHaE8&pid=Api&P=0&h=220' },
  { name: 'Beauty', image: 'https://tse1.mm.bing.net/th?id=OIP.pt_qA846fb2KjFcaZbOzEQHaE7&pid=Api&P=0&h=220' },
  { name: 'Sports', image: 'https://tse2.mm.bing.net/th?id=OIP.-80mgDVfMSRPFEh08uDETAAAAA&pid=Api&P=0&h=220' },
  { name: 'Toys', image: 'https://i0.wp.com/goldcoastgirlblog.com/wp-content/uploads/2017/12/best-toys-for-a-one-year-old-12.jpg?w=1250' },
  { name: 'Furniture', image: 'https://tse2.mm.bing.net/th?id=OIP.MguHwaAGef5sIRsozZBingHaFG&pid=Api&P=0&h=220' },
];

const ShopByCategory = () => {
  return (
    <div className="category-section">
      <h2>Shop by Category</h2>
      <div className="category-grid">
        {categories.map((category, index) => (
          <div className="category-card" key={index}>
            <img src={category.image} alt={category.name} />
            <div className="category-name">{category.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShopByCategory;
